.version-notifier {
  &__container {
    position: absolute;
    top: calc(50vh - 331px / 2);
    left: calc(50vw - 672px / 2);
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 672px;
    height: 331px;
    color: $secondary;
    background-color: #fff;
    border-radius: 5px;
    opacity: 1;

    h2 {
      margin-top: 30px;
      margin-bottom: 0;
    }

    p {
      width: 85%;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  &__actionbar {
    display: flex;
    flex-direction: row;
    gap: 20px;
    & > * {
      padding: 15px;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    z-index: 4;
    width: 100vw;
    height: 100vh;
    background-color: #25313790;
    opacity: 0.7;
  }
}
