.mui-table {
  table.MuiTable-root {
    margin-bottom: 30px;
    border-collapse: collapse;

    td,
    tr {
      height: 57px;
    }

    .MuiTableCell-stickyHeader,
    .MuiTableCell-head {
      color: $accent-primary;
      font-weight: 600;
      background: transparent;
      border: 1px solid #fff;
    }

    tr.mui-table__edit-row {
      position: relative;

      td:last-child {
        // cell with edit button.
        display: flex;
        align-items: center;
        padding: 0;
        border: 0;
      }
    }

    td.MuiTableCell-root {
      min-width: 100px;
      padding: 5px 15px;
      color: #fff;
      font-size: 1rem;
      border: 1px solid #fff;
    }
  }
}

@media (max-width: $screens-sm) {
  .mui-table {
    table.MuiTable-root {
      td,
      tr {
        height: 37px;

        .MuiTableCell-root {
          font-size: 14px;
          line-height: 1.1;
        }
      }

      .MuiTableCell-head {
        line-height: 1.1;
      }
    }
  }

  button.button-edit-row {
    position: absolute;
    margin: 0;
  }
}
