.mobile-nav {
  position: fixed;
  top: 0;
  left: -650px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: $primary;
  transition: left 100ms ease-in;

  &__nav-icon {
    position: absolute;
    display: inline-block;
    height: 18.4px;
    margin-left: 10px;
    fill: $primary;
  }

  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-bottom: 20px;
    box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.28);

    span {
      margin: 0;
      color: #fff;
      font-size: 1rem;
    }

    button.hamburger {
      position: absolute;
      top: 6px;
      right: 13px;
      padding: 0;
    }
  }
}

.mobile-nav__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;

  ul {
    width: 100%;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid rgb(143, 143, 143);

      a {
        padding: 10px 0;
        color: #fff;
        font-size: 1rem;
        text-align: center;
        text-decoration: none;
      }
    }
  }
}
