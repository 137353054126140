.kd-input {
  display: flex;
  margin: 0 0 25px;
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }

  .MuiOutlinedInput-root {
    height: $control-height;
    font-size: 20px;
    font-family: inherit;
    border-radius: $radius-base;

    @media (max-width: $screens-sm) {
      height: $control-height-mobile;
      font-size: 16px;
    }
  }

  .MuiFormHelperText-root {
    width: calc(100% - 10px);
    padding: 2px 5px;
    font-size: 14px;
    margin: 0 5px 0 5px;
    font-weight: 500;
    font-family: inherit;
    color: white;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 200;

    &.Mui-error {
      background: $warning;
    }
  }

  .MuiFormLabel-root {
    font-family: inherit;
    left: inherit;
    position: relative;
    top: inherit;
    left: inherit;
    margin-bottom: 15px;
    color: inherit;
    font-size: 20px;
    transform: none;

    &.Mui-error {
      color: $form-label-color;
    }

    &.Mui-disabled {
      color: $form-label-color;
      -webkit-text-fill-color: $form-label-color;
    }

    @media (max-width: $screens-sm) {
      font-size: 16px;
    }
  }

  .MuiFormLabel-filled {
    + div > input {
      background: transparent !important;
      color: $form-label-color !important;
    }
  }

  &:not(.kd-input_search) {
    .MuiOutlinedInput-input {
      padding: 18.5px 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline,
  .MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline {
    border-color: $border-color;
    border-width: $control-border-width;

    legend {
      max-width: 0;
    }
  }

  .MuiButtonBase-root {
    @media (max-width: $screens-sm) {
      padding-left: 0;
    }
  }

  .MuiInputBase-root {
    &.Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline,
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: $accent-primary;
      }
    }

    input {
      background: transparent !important;
      color: $control-color !important;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &::placeholder {
        color: $control-color;
        opacity: 0.8;
      }
    }
  }

  &:not(.kd-input_search) {
    .MuiInputBase-root.Mui-focused {
      svg {
        path {
          stroke: $accent-primary;
        }
      }
    }
  }

  // Right icon KdInput styles

  &_icon {
    .MuiInputAdornment-positionEnd {
      @media (min-width: calc($screens-sm + 1px)) {
        position: absolute;
        right: -30px;
      }
    }
  }

  // No active input styles
  .MuiInputBase-root.Mui-disabled input {
    color: $disabled !important;
    -webkit-text-fill-color: inherit !important;
  }

  &_readonly {
    .MuiInputBase-root.Mui-disabled input,
    .MuiFormControl-root.Mui-disabled input {
      color: $content-color !important;
      cursor: text;
    }

    @media (min-width: calc($screens-sm + 1px)) {
      .MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline,
      .MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline {
        border-color: transparent;
      }
    }
  }

  // Search field styles

  &_search {
    .MuiIconButton-root {
      padding: 0;
    }
  }

  // Autocomplete input styles

  &_autocomplete {
    .MuiFormControl-root {
      margin: 0;
    }

    .MuiFormLabel-root {
      display: none;
    }

    .MuiAutocomplete-endAdornment {
      top: calc(50% - 18px);
    }

    .MuiIconButton-label {
      font-size: 32px;
      color: $border-color;
    }

    .MuiOutlinedInput-root {
      padding: 0;

      &.Mui-focused {
        svg {
          path {
            stroke: transparent !important;
          }
        }
      }
    }

    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
      .MuiAutocomplete-input {
      padding: 0 20px;
      height: 100%;
    }

    &.kd-input_readonly {
      .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
        .MuiAutocomplete-input {
        text-overflow: inherit;
      }
    }
  }
}

// Special focus styles for Organisation Address block

.editable-area {
  .kd-input {
    &:not(.kd-input_autocomplete) {
      fieldset.MuiOutlinedInput-notchedOutline,
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: $accent-primary;
      }

      svg path {
        stroke: $accent-primary;
      }
    }
  }
}

// Checkbox with label

.kd-checkbox {
  svg {
    color: $accent-primary;
  }
}

.MuiAutocomplete-popper {
  > div {
    background-color: $control-bg;
    color: $control-color;
    border: $control-border-width solid $accent-primary;
    border-radius: $radius-base;
    font-size: 16px;
    margin: 10px 0;
    > * > * {
      border-radius: $radius-base;
      padding: 3px 10px;
      margin: 0 10px;

      &:hover,
      &:focus {
        background-color: $accent-secondary;
      }

      + * {
        margin-top: 12px;
      }
    }
  }
}
