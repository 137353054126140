.edit-row__edit-dropdown-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 10px;

  .edit-row__dropdown-button {
    @extend %button-reset;
    z-index: 0;
    min-width: 32px;
    min-height: 32px;
    padding: 0;
    background: $accent-primary;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.33);

    img,
    svg {
      display: block;
      max-width: 16px;
      max-height: 16px;
      margin: auto;
      fill: $button-text-color-hover;
    }

    &:hover {
      cursor: pointer;
      filter: brightness(120%);
    }

    &.--active {
      background: $warning;
    }
  }
}

.dropdown-button__dialog {
  position: absolute;
  top: -55px;
  right: 90px;
  z-index: 3;
  width: 120px;
  padding: 5px 0;
  padding: 5px 0;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.73);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      width: 100%;

      button {
        @extend %button-reset;
        display: block;
        width: 100%;
        margin: 2px 0;
        padding: 5px;
        background: #fff;

        &:hover {
          color: #fff;
          background: $primary;
          cursor: pointer;
        }
      }
    }
  }
}
