.view-container {
  height: calc(100vh - 80px);
  overflow-y: auto;

  @media (max-width: $screens-sm) {
    height: calc(var(--windowInnerHeight) - 60px);
    margin-top: 0;
    padding-bottom: 0;
  }

  &-external {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    overflow: auto;
    position: relative;
  }
}

.user-profile-icon {
  svg {
    width: 23px;
    height: 23px;
  }
}

.user-profile-icon-small {
  svg {
    width: 20px;
    height: 20px;
    margin-left: 3px;
  }
}
