// view animations
.animation-view-fade-enter {
  opacity: 0;
}

.animation-view-fade-enter-done,
.animation-view-fade-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}

.mobile-nav-slide-enter-done {
  left: 0 !important;
  transition: left 100ms ease-in;
}

@keyframes animate-toast-success {
  0% {
    right: -600px;
  }

  20% {
    right: 60px;
  }

  80% {
    right: 60px;
  }

  100% {
    right: -600px;
  }
}

@keyframes animate-toast-error {
  0% {
    right: -600px;
  }

  100% {
    right: 60px;
  }
}

@keyframes animate-toast-success-mobile {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes animate-toast-error-mobile {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.toast {
  animation-timing-function: ease-in-out;

  &-success {
    animation-name: animate-toast-success;
    animation-duration: 5s;
  }

  &-success-mobile {
    animation-name: animate-toast-success-mobile;
    animation-duration: 5s;
  }

  &-error-mobile {
    animation-name: animate-toast-error-mobile;
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
  }

  &-error {
    animation-name: animate-toast-error;
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
  }
}