.MuiFormControl-root {
  margin: 16px 0;
}

.MuiInputBase-root,
.MuiFormControl-root {
  width: 100%;
  color: $form-text-color;

  .MuiFormLabel-root.Mui-focused,
  input {
    color: $form-text-color;
  }

  .MuiFormLabel-root.Mui-focused,
  label {
    color: $form-label-color;

    &.Mui-disabled {
      color: $disabled;
    }
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    border-color: $form-text-color;
  }

  &:hover {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $accent-primary;
      filter: brightness(110%);
    }
  }

  &.Mui-disabled {
    color: $disabled;

    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $disabled;
    }

    input {
      color: $disabled;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.-inputMultiline {
    white-space: pre-wrap;
  }

  &.Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $accent-primary;
      filter: brightness(110%);
    }
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    color: #fff;
  }
}

// override datePicker error styling
.MuiFormControl-root {
  p.Mui-error {
    margin-top: 0;
  }
}

.MuiAutocomplete-endAdornment {
  .MuiAutocomplete-popupIndicator {
    width: initial !important;
  }
}
