.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: $screens-sm) {
    padding: 10px;
  }
}

main {
  &.loading {
    justify-content: center;
  }
}

%a-link {
  display: block;
  color: $accent-primary;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
}

a {
  @extend %a-link;
}

h1,
h2,
h3,
h4,
h5,
h6,
.MuiTypography-root {
  font-family: $title-font;
}

.popover-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @at-root [data-popper-placement*='right'] & {
    left: -8px;
    border-width: 8px 8px 8px 0;
    border-color: transparent $selected-date-range-background transparent
      transparent;
  }

  @at-root [data-popper-placement*='left'] & {
    right: -8px;
    border-width: 8px 0 8px 8px;
    border-color: transparent transparent transparent
      $selected-date-range-background;
  }
}

%full-page-layout {
  margin-left: -30px;
  margin-right: -30px;
  width: calc(100% + 60px);
}

div.kd-modal {
  @media (max-height: 500px) {
    margin: 60px 0;
    top: 50%;
  }
}

.scroll-y {
  margin-right: -15px;
  padding-right: 8px;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #44565e;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.scroll-y-no-padding {
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #62777e;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.scroll-y-no-padding-invis {
  &::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: #62777e;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

// Carousel styles

.slick-slider {
  padding: 0 5px;

  .slick-slide {
    padding: 0 10px;
  }

  .slick-arrow {
    &.slick-disabled {
      opacity: 0;
    }
  }
}

// Make google logo visible on mobile

.gm-style img[alt='Google'],
.gm-style > div:last-child > div[style*='position: absolute;'] {
  @media (max-width: $screens-sm) {
    transform: translateY(-30px);
  }
}
