.landing-view {
  background: url(../../../public/background.jpg) no-repeat center center fixed;
  @media (max-width: $screens-sm) {
    h1 {
      text-align: center;
      white-space: wrap;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .landing-view__container {
    display: flex;
    flex-direction: row;
    @media (max-width: $screens-sm) {
      flex-direction: column;
    }

    align-items: center;
    -ms-flex-justify: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    max-width: 1700px;
    padding: 40px;

    .landing-view__box {
      display: flex;

      svg {
        transition: all 0.2s ease-in;
        fill: #62777e;
        stroke: #62777e;
        width: 100%;
        height: 100%;
      }

      .landing-view__box__button {
        @extend %button-reset;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 197px;
        margin: 0 auto;
        padding: 45px;
        background: #253137;
        border: 3px solid #62777e;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
          border-color: #ffffff;

          svg {
            transition: all 0.2s ease-in;
            fill: $button-hover-background;
            stroke: $button-hover-background;

            .head-outline,
            .windows {
              stroke: $button-hover-background;
            }

            .head-outline {
              stroke-width: 5px;
            }
          }
        }

        .landing-view__box__image {
          width: 100%;
          height: 100%;

          &:hover {
            fill: #fcbc00;
          }
        }
        .landing-view__box__title {
          transition: all 0.2s ease-in;
          text-align: center;
          font-size: 1rem;
          letter-spacing: 0px;
          color: #f2f2f2;
          opacity: 1;
          margin-bottom: 10px;
          font-style: bold;
        }

        &:hover {
          .landing-view__box__title {
            transition: all 0.2s ease-in;
            color: #fcbc00;
          }
          .landing-view__box__image {
            color: #fcbc00;
            fill: #fcbc00;
            stroke: #fcbc00 !important;
          }
        }
      }
    }
  }
}
