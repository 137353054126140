@mixin size($size) {
  height: $size;
  width: $size;
}

//----- Chart Styles

.chart {
  $this: &;

  position: relative;
  height: calc(100% - 50px);
  min-height: 100px;

  &__graph {
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }

  // Line Chart styles

  &_line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  // Doughnut Chart styles

  &_doughnut {
    #{$this}__graph {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 120px;
      min-height: 120px;

      @media (min-width: $screens-lg) {
        width: 200px;
        height: 200px;
      }

      @media (min-width: $screens-sm) and (max-width: calc($screens-lg - 1px)) {
        max-width: 200px;
        max-height: 200px;
      }

      @media (max-width: $screens-sm) {
        @include size(90%);
      }
    }

    &.chart-bay-usage {
      margin-top: -15px;

      @media (min-width: $screens-lg) {
        #{$this}__graph {
          width: 160px;
          height: 160px;
        }
      }

      @media (min-width: $screens-md) and (max-width: $screens-lg) {
        #{$this}__graph > div:first-child {
          font-size: 35px;
        }
      }

      @media (min-width: $screens-md) and (max-width: 840px) {
        + * {
          bottom: 8px;
        }
      }
    }
  }

  // Bar Chart styles

  &_bar {
    @media (max-width: $screens-sm) {
      height: auto;
      margin-top: 40px;
    }

    #{$this}__graph {
      @media (min-width: calc($screens-2xl + 1px)) {
        height: 100%;
        min-height: 340px;
        max-height: 500px;
      }

      @media (min-width: $screens-sm) and (max-width: $screens-2xl) {
        height: 420px;
      }

      @media (max-width: $screens-sm) {
        height: 340px;
        margin-left: -10px;
        margin-right: -10px;
      }
    }

    &_secondary {
      #{$this}__graph {
        @media (min-width: calc($screens-2xl + 1px)) {
          height: 420px;
        }
      }
    }
  }

  // Sector Chart styles

  &_sector {
    @media (min-width: calc($screens-sm + 1px)) and (max-width: $screens-2xl) {
      min-height: 400px;
    }

    #{$this}__graph {
      margin: 20px auto 0;

      @media (min-width: calc($screens-2xl + 1px)) {
        @include size(75%);
      }

      @media (min-width: calc($screens-sm + 1px)) and (max-width: $screens-2xl) {
        @include size(320px);
      }

      @media (max-width: $screens-sm) {
        @include size(90%);
      }
    }
  }
}
