@import 'tokens';
// All variables should be inherited from theme file schema variables, and should NOT be hardcoded.
$success: var(--success);
$disabled: var(--disabled);
$warning: var(--warning);
$primary: var(--primary);
$secondary: var(--secondary);
$accent-primary: var(--accentPrimary);
$accent-secondary: var(--accentSecondary);
$button-background: var(--accentPrimary);
$button-border-style: var(--buttonBorderStyle);
$button-hover-border-style: var(--buttonHoverBorderStyle);
$button-border-radius: var(--buttonBorderRadius);
$button-background-image: var(--buttonBackgroundImage);
$button-hover-background-image: var(--buttonHoverBackgroundImage);
$button-text-color: var(--buttonTextColor);
$button-text-color-hover: var(--buttonTextColorHover);
$button-hover-background: var(--buttonHoverBackground);
$button-font: var(--titleFont);
$top-bar-background: var(--primary);
$form-text-color: var(--formTextColor);
$form-label-color: var(--formLabelColor);
$helper-text-color: var(--helperTextColor);
$title-font: var(--titleFont);
$primary-font: var(--primaryFont);
$number-plate-font: var(--numberPlateFont);
$background-image: var(--background-image);
$background-color: var(--background-color);
$background-position: var(--background-position);
$background-repeat: var(--background-repeat);
$background-size: var(--background-size);
$daypicker-background: var(--pickerBgColor);
$selected-date-range-background: var(--selectedDateRangeBackground);
$selected-date-range-border: var(--selectedDateRangeBorder);
$selected-date-range-color: var(--selectedDateRangeColor);
$border-color: var(--borderColor);
$content-color: var(--content);

// element widths ============
$form-width: 540px;

// controls ============
$radius-base: var(--borderRadiusBase);
$control-border-width: var(--controlBorderWidth);
$control-height: var(--controlHeight);
$control-height-mobile: var(--controlHeightMobile);
$control-color: var(--controlColour);
$control-bg: var(--controlBg);
$control-hover-bg: var(--accentSecondary);
