$daypicker-range-space: 46px;
$daypicker-range-space-mobile: 28px;
$nav-icon-size: 20px;

@mixin daypicker-range() {
  &:before {
    position: absolute;
    left: -$daypicker-range-space;
    background-color: $selected-date-range-background;
    border-top: 1px solid $selected-date-range-border;
    border-bottom: 1px solid $selected-date-range-border;
    content: '';
    pointer-events: none;
    z-index: 0;
    @content;
  }
}

@mixin daypicker-range-lateral-points() {
  @include daypicker-range() {
    top: -1px;
    width: calc(100% + 27px);
    height: calc(100% + 2px);

    @content;
  }
}

//-------- Daypicker styles

.daypicker {
  overflow: hidden;

  @media (min-width: calc($screens-sm + 1px)) {
    max-width: 470px;
  }

  &-caption {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    border-top: 1px solid $daypicker-background;
    border-bottom: 1px solid $daypicker-background;

    &__label {
      font-weight: 600;
      font-size: 20px;
    }
  }

  &-nav-btn {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);

    .rdp-nav_icon {
      width: $nav-icon-size;
      height: $nav-icon-size;

      path {
        fill: rgba(255, 255, 255, 0.6);
      }
    }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
      border: 0 none !important;

      .rdp-nav_icon path {
        fill: $accent-primary;
      }
    }

    &_prev {
      left: 0;
    }

    &_next {
      right: 0;
    }
  }

  &-table {
    width: 100%;
    margin-top: 10px;

    .rdp-head_cell {
      height: 50px;
      font-size: 20px;
      text-transform: capitalize;

      @media (max-width: $screens-sm) {
        font-size: 15px;
      }
    }

    // Specific styles when start/end dates when they are lateral extremes

    .rdp-cell {
      height: 50px;

      &:first-child {
        .daypicker-day-end:not(.daypicker-day-start) {
          @include daypicker-range-lateral-points;
        }
      }

      &:last-child {
        .daypicker-day-start:not(.daypicker-day-end) {
          @include daypicker-range-lateral-points() {
            left: 50%;
          }
        }
      }
    }
  }

  // DayPicker days styles

  .rdp-day {
    border: 1px solid transparent;
    display: inline-flex;
    font-size: 18px;
    overflow: inherit;
    height: 40px;
    width: 40px;

    @media (max-width: $screens-sm) {
      font-size: 15px;
    }

    span {
      position: relative;
      z-index: 200;
    }

    &.daypicker-day-selected {
      background-color: $selected-date-range-background;
      border-color: $selected-date-range-border;
      color: $selected-date-range-color;
    }

    &.rdp-day_today {
      color: $accent-primary;
    }

    &:hover,
    &:focus {
      &:not([disabled]) {
        border-width: 1px;

        &:not(.daypicker-day-selected) {
          background-color: $selected-date-range-background;
          border-color: transparent;
        }

        &.daypicker-day-selected {
          background-color: $selected-date-range-background;
          border-color: $selected-date-range-border;
          color: $selected-date-range-color;

          &.daypicker-day-middle {
            border: 0 none;
            color: $accent-primary;
          }
        }
      }
    }
  }

  .daypicker-day {
    &-start,
    &-end {
      background-color: $selected-date-range-background;
      border: 1px solid $selected-date-range-border;
      color: $selected-date-range-color;
      z-index: 100;

      &:hover,
      &:focus,
      &:active {
        border: 1px solid $selected-date-range-border;
      }
    }

    &-middle {
      border: 0 none;
      overflow: inherit;

      @include daypicker-range() {
        top: 0;
        height: 100%;
        width: calc(100% + #{$daypicker-range-space * 2});

        @media (max-width: 420px) {
          left: -$daypicker-range-space-mobile;
          width: calc(100% + #{$daypicker-range-space-mobile * 2});
        }
      }
    }

    &-end_close {
      border-color: transparent;
      overflow: inherit;
      z-index: 0;

      @include daypicker-range-lateral-points() {
        @media (max-width: 420px) {
          left: -28px;
          width: calc(100% + 10px);
        }
      }

      &:after {
        position: absolute;
        right: 0;
        top: -1px;
        height: 40px;
        width: 40px;
        border: 1px solid $selected-date-range-border;
        border-radius: 50%;
        content: '';
        z-index: 0;
      }
    }
  }
}
