$button-hover-transition-in: all 0.3s ease-in-out;
$button-hover-transition-out: all 0.2s ease-out;

%button-reset {
  border: 0;
  outline: none;
}

.button,
button.MuiButton-root {
  @extend %button-reset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 210px;
  height: 40px;
  margin: 10px auto;
  padding: 12px 5px;
  font-weight: 600;
  font-size: 0.9rem;
  font-family: $button-font;
  letter-spacing: 1px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  transition: $button-hover-transition-out;

  &:hover {
    &:not(:disabled) {
      font-size: 0.95rem;
      box-shadow: none;
      cursor: pointer;
      filter: brightness(115%);
      transition: $button-hover-transition-in;
    }
  }

  &:disabled {
    cursor: not-allowed;
    filter: brightness(70%);
  }

  &.--outline {
    color: #fff;
    text-transform: capitalize;
    background: none;
    border: 1px solid $primary;

    &:hover,
    &:focus {
      color: $secondary;
      filter: brightness(130%);
      transition: $button-hover-transition-in;
    }
  }
  // modifiers
  &.--full-width {
    max-width: 100%;
  }

  &.--small {
    width: 100%;
    max-width: 150px;
  }

  &.--warning {
    color: #fff;
    background: $warning;
    border: 0;

    &:hover,
    &:focus {
      color: #fff;
      background: $warning;
      filter: brightness(130%);
      transition: $button-hover-transition-in;
    }
  }

  &.--success {
    margin: 0;
    color: #fff;
    background: $success;

    &:hover,
    &:focus {
      filter: brightness(130%);
      transition: $button-hover-transition-in;
    }
  }

  &.--selected {
    background: $success;
  }

  &.--square-image-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    max-width: 240px;
    height: 200px;
    padding: 15px;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    background: none;
    border: 3px solid $primary;
    border-radius: 10px;

    > svg {
      width: 100%;
      max-height: 100px;
      fill: none;
      stroke: #fff;
    }

    &.--selected {
      color: $accent-primary;
      border-color: $accent-primary;
    }

    // hover focus states.
    &:hover,
    &:focus {
      font-size: initial;
      background-color: transparent;
      border: 3px solid $accent-primary;
      cursor: pointer;
      transition: $button-hover-transition-in;

      img {
        fill: $accent-primary;
      }
    }
  }
  @media (max-width: $screens-sm) {
    height: 40px;
    padding: 5px;
  }
}

// override default focus state for a tags and button role elements
[role='button'],
a {
  &:focus {
    outline-color: $accent-primary;
  }
}
