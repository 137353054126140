.form {
  .form__error-message,
  .MuiFormHelperText-root.Mui-error {
    display: block;
    width: 100%;
    margin: 0;
    margin-top: -16px;
    padding: 0 3px;
    color: #fff;
    font-weight: 800;
    font-size: 0.7rem;
    text-align: left;
    background-color: $warning;
    border: 1px solid $warning;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    &::before {
      display: inline;
      margin-right: 5px;
      color: #fff;
      content: '\26A0';
    }
  }
}
