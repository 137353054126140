.logout-notification-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.logout-notification-modal__modal-inner {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 3;
  min-width: 40%;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  color: rgb(0, 0, 0);
  text-align: center;
  background-color: rgb(255, 255, 255);
  border-radius: 7px;
  transform: translate(-50%, -50%);

  @media (max-width: $screens-sm) {
    min-width: 80%;
  }

  button {
    margin-bottom: 55px;
  }

  h6 {
    margin-bottom: 30px;
    font-size: 1em;
  }

  h2 {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 1.6em;
  }

  svg {
    margin-top: 45px;
    margin-bottom: 30px;
  }
}
