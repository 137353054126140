$hamburger-colour: #fff;
$hamburger-padding-x: 5px !default;
$hamburger-padding-y: 5px !default;
$hamburger-layer-width: 25px !default;
$hamburger-layer-height: 3px !default;
$hamburger-layer-spacing: 4px !default;
$hamburger-layer-color: $hamburger-colour !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;
$calc: 0, ($hamburger-layer-spacing + $hamburger-layer-height) * -1, 0;

.hamburger {
  display: inline-block;
  margin: 0;
  padding: $hamburger-padding-y $hamburger-padding-x;
  overflow: visible;
  color: inherit;

  // Normalize (<button>)
  font: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;

  transition-property: opacity, filter;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    } @else {
      opacity: $hamburger-hover-opacity;
    }
  }

  &.is-active {
    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter;
      } @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }
}

.hamburger-box {
  position: relative;
  display: inline-block;
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
}

.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: calc($hamburger-layer-height / -2);

  &,
  &::before,
  &::after {
    position: absolute;
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
  }

  &::before,
  &::after {
    display: block;
    content: '';
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

.hamburger--collapse {
  .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 0.13s;

    &::after {
      top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        opacity 0.1s linear;
    }

    &::before {
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-inner {
      transform: translate3d($calc) rotate(-45deg);
      transition-delay: 0.22s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::after {
        top: 0;
        opacity: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          opacity 0.1s 0.22s linear;
      }

      &::before {
        top: 0;
        transform: rotate(-90deg);
        transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}
