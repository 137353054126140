.u-helper-text {
  display: inline-block;
  color: $helper-text-color;
  font-size: 0.7rem;
  text-align: left;

  &:hover {
    color: $accent-primary;
    transition: color 0.3s ease;
  }
}

.u-link-button {
  @extend %a-link;
  display: inline;
  min-width: initial;
  background: none;

  &:hover {
    background: none;
  }
}

.u-block-link {
  display: block;
}

.u-highlight-title {
  color: $accent-primary;
}

.u-sub-header {
  display: block;
  font-size: 12px;
  font-style: italic;
  text-align: left;
}

.u-border-bottom {
  border-bottom: 1px solid #fff;
}

.u-text-notavailable {
  color: $disabled;
  font-size: 1rem;
  font-style: italic;
}

.u-text-italic {
  font-style: italic;
}

.u-hidden {
  display: none;
  visibility: hidden;
}

.u-text-yellow {
  color: $primary;
}

.u-text-center {
  text-align: center;
}

.u-text-capitalise {
  text-transform: capitalize;
}

.MuiFormControl-root.u-text-capitalise {
  input {
    text-transform: capitalize;
  }
}

.--taken {
  background-image: url('../assets/images/crossedBackground.svg');
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}

.time-slot-picker-safari-fix {
  -webkit-transform: translate3d(0, 0, 0);
}
