main.error-view {
  margin-top: 0;

  h1 {
    .primary-heading__bold {
      color: $accent-primary;
    }
  }

  h2.static-error-page__subheading {
    color: $accent-primary;
  }

  .static-error-page {
    background-image: linear-gradient(123deg, $secondary 30%, $primary 100%);
  }
}
